import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '@app/settings.service';
import { environment } from '@environments/environment';
import * as sharedModels from '@shared/models';
import { SelectComponent } from '@shared/modules/select/select.component';
import { ISelectService } from '@shared/modules/select/user-select.model';
import * as sharedServices from '@shared/services';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import * as models from './models';
import * as services from './services';

type LeadFormValue = {
  [LeadFormControlNameList.OperationTypeId]: number;
  [LeadFormControlNameList.CityId]: number;
  [LeadFormControlNameList.ObjectTypeId]: number;
  [LeadFormControlNameList.ClientName]: string;
  [LeadFormControlNameList.ClientLogin]: string;
  [LeadFormControlNameList.Email]?: string;
  [LeadFormControlNameList.Note]?: string;
};

enum LeadFormControlNameList {
  OperationTypeId = 'operationTypeId',
  CityId = 'cityId',
  ObjectTypeId = 'objectTypeId',
  ClientName = 'clientName',
  ClientLogin = 'clientLogin',
  Email = 'email',
  Note = 'note',
}

@Component({
  templateUrl: './sell-real-property-application-form.component.html',
  styleUrls: ['./sell-real-property-application-form.component.scss'],
})
export class SellRealPropertyApplicationFormComponent implements OnInit, OnDestroy {
  public form: FormGroup;

  @ViewChild('citySelector') private citySelector: SelectComponent;

  public readonly addressType = sharedModels.AddressType;
  public readonly dictionaryList = sharedModels.DictionaryList;

  public operationInFly = false;

  public addressDatasource: ISelectService<number | string>;
  public dictionaryDatasource: ISelectService<boolean | number>;

  public parentAddressCode: string = null;
  isMobile: boolean;
  constructor(
    private route: ActivatedRoute,
    private _dialogRef: DynamicDialogRef,
    private router: Router,
    private fb: FormBuilder,
    private dictionaryService: sharedServices.DictionaryService,
    private addressService: sharedServices.AddressService,
    private realPropertyLeadService: services.RealPropertyLeadService,
    private messageService: MessageService,
    private _settingsService: SettingsService,
    private _googleAnalyticsService: sharedServices.GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    const path = this.route.snapshot.firstChild.url[0]?.path;
    this._settingsService.isMobile().subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
    if (path && typeof path === 'string') {
      const country = environment.appDefaultSettings.countries[path] as sharedModels.AddressObject;
      if (country) {
        this.parentAddressCode = country.code;
      } else {
        console.warn('Country not provided');
      }
    }

    this.addressDatasource = this.addressService;

    this.dictionaryDatasource = this.dictionaryService;

    this.form = this.fb.group({
      [LeadFormControlNameList.OperationTypeId]: this.fb.control(1, [Validators.required]),
      [LeadFormControlNameList.CityId]: this.fb.control(null, [Validators.required]),
      [LeadFormControlNameList.ObjectTypeId]: this.fb.control(1, [Validators.required]),
      [LeadFormControlNameList.ClientName]: this.fb.control(null, [Validators.required]),
      [LeadFormControlNameList.ClientLogin]: this.fb.control(null, [Validators.required]),
      [LeadFormControlNameList.Email]: this.fb.control(null, [Validators.pattern('^[a-z0-9.%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      [LeadFormControlNameList.Note]: this.fb.control(null, []),
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.operationInFly = true;
      const data = this.form.value as LeadFormValue as models.RealPropertyLeadParams;
      this.realPropertyLeadService
        .sendRealPropertyLead(data)
        .pipe(
          tap(() => (this.operationInFly = false)),
          filter((res) => typeof res === 'number'),
          tap(() => {
            this.messageService.add({ severity: 'success', summary: 'Заявка отправлена' });
            this._googleAnalyticsService.event('send_form_application', { event_category: 'send application', status: 'success' });
            this._dialogRef.close();
          }),
          catchError(() => {
            this.operationInFly = false;
            this._googleAnalyticsService.event('error_send_form', { field: 'send_form_application' });
            this.messageService.add({ severity: 'error', summary: 'Не удалось отправить форму' });
            return of(null);
          }),
        )
        .subscribe();
    }
  }

  public onOperationTypeSelect(event: any): void {
    this.form.get(LeadFormControlNameList.OperationTypeId).setValue(event);
  }

  public onCountrySelect(event: any): void {
    this.parentAddressCode = event;
    this.citySelector.additionalFilter.addressType = event;
    this.citySelector.refreshList();
  }

  public onCitySelect(event: any): void {
    this.form.get(LeadFormControlNameList.CityId).setValue(event);
  }

  public onObjectTypeSelect(event: any): void {
    this.form.get(LeadFormControlNameList.ObjectTypeId).setValue(event);
  }

  onCancel() {
    this._dialogRef.close();
  }

  resetForm() {
    this.form.reset();
  }

  ngOnDestroy() {
    this._dialogRef.close();
    this.router.navigate(['', { outlets: { modal: null } }], { queryParamsHandling: 'preserve' }).then();
  }
}
