import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@app/settings.service';
import { environment } from '@environments/environment';
import * as sharedModels from '@shared/models';
import * as selectModels from '@shared/modules/select/user-select.model';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AddressService implements selectModels.ISelectService<number | string> {
  private readonly url: string = `${environment.apiGisManagerUrl}/open-api/address`;

  public countryList$: Observable<sharedModels.AddressObject[]> = this.getAddressObjectList(sharedModels.AddressObjectTypeList.Country);

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
  ) {}

  public getAddressObjectList(objectTypeId: sharedModels.AddressObjectTypeList): Observable<sharedModels.AddressObject[]> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return this.http.get<sharedModels.GetChildAddressObjectListResp>(`${this.url}/getObjects?typeId=${objectTypeId}`).pipe(
      map((res) =>
        res.data.map(
          ({ addressObject: { addressObject: address } }) =>
            ({
              id: address.id,
              code: address.code,
              name: address.name[currentLanguageAsKey as keyof sharedModels.Localization],
            }) as sharedModels.AddressObject,
        ),
      ),
    );
  }

  public getDistrictList(parentCode: string) {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return this.http.get<sharedModels.GetChildAddressObjectListResp>(`${this.url}/getObjects?typeId=2&parentCode=${parentCode}`).pipe(
      map(({ data }: sharedModels.GetChildAddressObjectListResp) => {
        return data.map((item) => {
          const { code, name } = item?.addressObject?.addressObject;
          return { value: code, label: name[currentLanguageAsKey as keyof sharedModels.Localization] };
        });
      }),
    );
  }

  public getChildAddressObjectList(
    parentCode: sharedModels.GetChildAddressObjectListQueryParams['parentCode'] = null,
    objectTypeId: sharedModels.AddressObjectTypeList[] = [],
  ): Observable<sharedModels.AddressObject[]> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return objectTypeId.length
      ? this.http
          .get<sharedModels.GetChildAddressObjectListResp>(`${this.url}/getObjects?parentCode=${parentCode}&${objectTypeId.map((id) => `typeId=${id}`).join('&')}`)
          .pipe(toAddressObject(currentLanguageAsKey))
      : this.http.get<sharedModels.GetChildAddressObjectListResp>(`${this.url}/getObjects?parentCode=${parentCode}`).pipe(toAddressObject(currentLanguageAsKey));
  }

  public fetchAddressObjectListByParentAddressObjectId(id: sharedModels.GetAddressObjectListByParentAddressObjectIdParams): Observable<sharedModels.GetAddressObjectListByParentAddressObjectIdResp> {
    return this.http.get(`${this.url}/getObjectListByParent/${id}`);
  }

  public fetchStreetListByParent(text: string, parentCode: string, pageInfo = { pageSize: 10, pageNumber: 0, sortBy: 'id', direction: 'ASC' }): Observable<sharedModels.AddressObject[]> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return this.http
      .post<sharedModels.GetShortStreetListByParentResp>(
        `${this.url}/getShortStreetsByParent`,
        // TODO :sharedModels.GetShortStreetListByParentParams
        { text, parentCode, ...pageInfo } as any,
      )
      .pipe(
        map((res) =>
          res.data.data.map(
            (address) =>
              ({
                id: address.id,
                code: address.code,
                name: address.name[currentLanguageAsKey as keyof sharedModels.AddressObject['name']] as string,
              }) as sharedModels.AddressObject,
          ),
        ),
      );
  }
  //TODO: sharedModels.GetStreetByCodeParams
  public getStreetByCode(streetCode: any): Observable<sharedModels.GetStreetByCodeResp> {
    return this.http.get(`${this.url}/getStreetByCode/${streetCode}`);
  }

  public fetchItemList(body: selectModels.FetchListRequestBody): Observable<selectModels.FetchListResponse<number | string>> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    if ((body as any)?.type) {
      switch ((body as any).type) {
        case sharedModels.AddressType.Country:
          return this.getAddressObjectList(sharedModels.AddressObjectTypeList.Country).pipe(
            map((res) => ({
              empty: res.length === 0,
              list: res.map((address) => ({
                value: address.code,
                label: address.name,
              })),
            })),
          );
        case sharedModels.AddressType.AllCity:
          return this.getChildAddressObjectList((body as any).addressType, [
            sharedModels.AddressObjectTypeList.MainCity,
            sharedModels.AddressObjectTypeList.AreaCenterCity,
            sharedModels.AddressObjectTypeList.City,
          ]).pipe(
            map((res) => ({
              empty: res.length === 0,
              list: res.map((address) => ({
                value: address.id,
                label: address.name,
              })),
            })),
          );
        default:
          return this.http
            .post<sharedModels.GetShortStreetListByParentResp>(`${this.url}/getShortStreetsByParent`, {
              pageNumber: body.pageNumber,
              text: body.searchData,
              parentCode: (body as any).addressCodes,
            } as sharedModels.GetShortStreetListByParentParams)
            .pipe(
              map((res) => ({
                empty: res.data.empty,
                list: res.data.data.map((address) => ({
                  value: address.code,
                  label: address.name[currentLanguageAsKey as keyof sharedModels.AddressObject['name']] as string,
                })),
              })),
            );
      }
    } else {
      return this.http
        .post<sharedModels.GetShortStreetListByParentResp>(`${this.url}/getShortStreetsByParent`, {
          pageNumber: body.pageNumber,
          text: body.searchData,
          parentCode: (body as any).addressCodes,
        } as sharedModels.GetShortStreetListByParentParams)
        .pipe(
          map((res) => ({
            empty: res.data.empty,
            list: res.data.data.map((address) => ({
              value: address.code,
              label: address.name[currentLanguageAsKey as keyof sharedModels.AddressObject['name']] as string,
            })),
          })),
        );
    }
  }

  public getShortStreetListByParent(body: sharedModels.GetShortStreetListByParentParams): Observable<{ value: string; label: string }[]> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return this.http.post<sharedModels.GetShortStreetListByParentResp>(`${this.url}/getShortStreetsByParent`, body).pipe(
      map((res) =>
        res.data.data.map((address) => ({
          value: address.code,
          label: address.name[currentLanguageAsKey as keyof sharedModels.AddressObject['name']] as string,
        })),
      ),
    );
  }

  public restoreSelectedCity(addressCodes: string, cityList: sharedModels.AddressObject[], routeCity = '') {
    const city = SettingsService.city;
    const cityFromAddress = cityList.find((city) => addressCodes.startsWith(city.code));
    if (city) {
      return city?.id;
    } else {
      SettingsService.city = cityFromAddress;
      return routeCity === 'kazakhstan' ? cityFromAddress?.id || environment.appDefaultSettings.cities.kazakhstan : null;
    }
  }
}

const toAddressObject = (currentLanguageAsKey: string) =>
  pipe(
    map((res: sharedModels.GetChildAddressObjectListResp) =>
      res.data.map(
        ({ addressObject: { addressObject: address } }) =>
          ({
            id: address.id,
            code: address.code,
            name: (address.name[currentLanguageAsKey as keyof sharedModels.Localization] as string) ?? '',
          }) as sharedModels.AddressObject,
      ),
    ),
  );
