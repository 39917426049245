<div class="p-d-flex p-flex-column p-ai-center form-container">

  <div class="p-d-flex p-jc-between header">
    <p>Заполните форму</p>
    <button (click)="onCancel()"><i class="pi pi-times"></i></button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="body">
      <div class="p-d-flex p-flex-column p-jc-between">
          <div class="p-d-flex "
            [ngClass]="{
              'p-flex-row p-jc-between': !isMobile,
              'p-flex-column': isMobile
            }">
            <div class="p-d-flex p-flex-column form-control">
              <label for="operationTypeId">Тип операции <span class="required">*</span>:</label>
              <app-select
                [initialLoad]="true"
                [additionalFilter]="{ dict: dictionaryList.OperationType }"
                [dataSource]="dictionaryDatasource"
                id="operationTypeId"
                bindLabel="label"
                bindValue="value"
                [selectedValue]="form.get('operationTypeId').value"
                placeholder="Выберите тип операции"
                [clearable]="false"
                (selected)="onOperationTypeSelect($event)">
              </app-select>
            </div>
  
            <div class="p-d-flex p-flex-column form-control">
              <label for="objectTypeId">Тип объекта <span class="required">*</span>:</label>
              <app-select
                [initialLoad]="true"
                [selectedValue]="form.get('objectTypeId').value"
                [additionalFilter]="{ dict: dictionaryList.ObjectType }"
                [dataSource]="dictionaryDatasource"
                [clearable]="false"
                [searchable]="false"
                bindValue="value"
                bindLabel="label"
                id="objectTypeId"
                (selected)="onObjectTypeSelect($event)">
              </app-select>
            </div>
          </div>
          <div class="p-d-flex"
          [ngClass]="{
            'p-flex-row p-jc-between': !isMobile,
            'p-flex-column': isMobile
          }">
            <div class="p-d-flex p-flex-column form-control">
              <label>Страна <span class="required">*</span>:</label>
              <app-select
                [selectedValue]="parentAddressCode"
                [initialLoad]="true"
                [clearable]="false"
                [additionalFilter]="{ type: addressType.Country }"
                [dataSource]="addressDatasource"
                bindValue="value"
                bindLabel="label"
                placeholder="Выберите страну"
                [pageable]="false"
                (selected)="onCountrySelect($event)">
              </app-select>
            </div>
            <div class="p-d-flex p-flex-column form-control">
              <label>Город <span class="required">*</span>:</label>
              <app-select
                  #citySelector
                  [disabled]="parentAddressCode === null"
                  [initialLoad]="false"
                  [clearable]="false"
                  [additionalFilter]="{ addressType: parentAddressCode, type: addressType.AllCity }"
                  [dataSource]="addressDatasource"
                  bindValue="value"
                  bindLabel="label"
                  placeholder="Выберите город"
                  [pageable]="false"
                  (selected)="onCitySelect($event)">
              </app-select>
            </div>
          </div>

         <div class="p-d-flex"
         [ngClass]="{
          'p-flex-row p-jc-between': !isMobile,
          'p-flex-column': isMobile
        }">
           <div class="p-d-flex p-flex-column form-control">
             <label for="clientLogin">Контакты <span class="required">*</span>:</label>
             <app-input-mask
               [unmask]="true"
               placeholder="+7 (___) ___-____"
               formControlName="clientLogin"
               mask="## (000) 000-0000">
             </app-input-mask> 
           </div>
 
           <div class="p-d-flex p-flex-column form-control">
             <label for="email">Email</label>
             <input
               id="email"
               type="email"
               name="email"
               class="control"
               pInputText
               formControlName="email"
               placeholder="Укажите email"
               autocomplete="off">
               <span class="error-message">
                 <ng-container *ngIf="form.get('email').invalid && form.get('email').touched">
                   Введите корректный email.
                 </ng-container>
               </span>
           </div>
         </div>

        <div class="p-d-flex p-flex-column form-control">
          <label for="clientName">Имя <span class="required">*</span>:</label>
          <input
            id="clientName"
            name="clientName"
            pInputText
            formControlName="clientName"
            autocomplete="off"
            placeholder="Укажите имя"
            required>
        </div>
      </div>
      <div class="p-d-flex p-flex-column form-control note">
        <label for="note">Примечание</label>
        <textarea
          id="note"
          name="note"
          formControlName="note"
          autocomplete="off"
          rows="3">
        </textarea>
      </div>
    </div>

    <div class="p-d-flex p-flex-row p-jc-between actions">
      <button
        pButton
        [disabled]="form.untouched"
        class="clear"
        type="reset"
        (click)="resetForm()"
        label="Очистить">
      </button>

      <button
        pButton
        [loading]="operationInFly"
        class="submit"
        type="submit"
        label="Отправить">
      </button>
    </div>
  </form>
</div>

<ng-template #cityField let-className="className">
  <div class="p-d-flex p-flex-column form-control {{className}}">
    <label>Город <span class="required">*</span>:</label>
    <app-select
        #citySelector
        [disabled]="parentAddressCode === null"
        [initialLoad]="false"
        [clearable]="false"
        [additionalFilter]="{ addressType: parentAddressCode, type: addressType.AllCity }"
        [dataSource]="addressDatasource"
        bindValue="value"
        bindLabel="label"
        placeholder="Выберите город"
        [pageable]="false"
        (selected)="onCitySelect($event)">
    </app-select>
  </div>
</ng-template>