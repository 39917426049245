<div class="p-d-flex p-flex-column p-col-12 p-p-0">
  <div class="contacts">
    <div class="social-media-text header-text">Связаться с нами: </div>
    <div class="social-media p-d-flex p-ai-center">
      <ng-container *ngFor="let link of socialLinks">
        <a target="_blank" [href]="link.link" class="item"><img [src]="link.icon" [alt]="link.alt"
            [title]="link.title"></a>
      </ng-container>
    </div>
    <div class="p-d-flex p-ai-center" *ngIf="!isShowContact()">
      <a class="p-d-flex phone-field" href="tel:+7{{phoneNumber}}">
        <i class="pi pi-phone icon"></i>
        <span class="header-text">+7{{ phoneNumber | mask: ' 000 000 00 00' }}</span>
      </a>
    </div>
  </div>
  <div class="p-d-flex p-jc-between header">
    <img [routerLink]="['/']" class='logo' src="../../../../assets/images/vitrina-logo.svg" alt="">
    <div class="actions">
      <button (click)="openRealPropertyLead()" id="CLICK_APPLY_APPLICATION">Подать заявку</button>
      <div class="icon-container">
        <svg [routerLink]="['/menu']" routerLinkActive="active" width="24" height="24" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <line x1="4" y1="5" x2="20" y2="5" stroke-width="2" stroke-linecap="round" />
          <line x1="4" y1="11" x2="20" y2="11" stroke-width="2" stroke-linecap="round" />
          <line x1="4" y1="17" x2="20" y2="17" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
    </div>
  </div>
</div>